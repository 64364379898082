import { isNewTheme } from "core/model/utils/featureFlags";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Roles } from "core/types";
import { ChipGroupContainer } from "ds_legacy/components/ChipGroup";
import InfoChip from "ds_legacy/components/InfoChip";
import { margin } from "ds_legacy/materials/metrics";
import { useSenderAccountContext } from "dsl/atoms/Contexts";
import { getCareseekerRoles } from "dsl/organisms/Accounts";

export function CareseekerAccountRolesChip({
  disabled,
  value,
}: {
  disabled?: boolean;
  value?: Roles;
}) {
  const { careseeker } = useSenderAccountContext();

  if (!careseeker)
    throw new Error(
      `[${CareseekerAccountRolesChip.name}] must be used within the SenderAccountContext`,
    );

  const getOntology = useGetOntology();

  const careseekerRoles = getCareseekerRoles(value, careseeker.id);

  return (
    <ChipGroupContainer margin="0">
      {careseekerRoles?.sort().map((role) => {
        return (
          <div key={role} style={{ margin: margin(0.5) }}>
            <InfoChip
              color={isNewTheme ? "primary" : "info"}
              label={getOntology({ type: "accountRoleShort", key: role })}
              variant="outlined"
              disabled={disabled}
            />
          </div>
        );
      })}
    </ChipGroupContainer>
  );
}
