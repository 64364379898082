import { IconButton } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  QUERY_PROGRESS_PENDING,
  REQUEST_ACTION_PATIENT_PREFERRED,
  SEARCH_TABLE_CONTACTED,
  SEARCH_TABLE_POTENTIAL,
} from "core/consts";
import { useSafeState } from "core/hooks";
import {
  AcceptedTableRequest,
  Auction,
  AuctionRequest,
  ContactedTableRequest,
  DeclinedTableRequest,
  PotentialReceiversTableCandidate,
  QueryProgress,
  RejectedTableRequest,
  SearchTable,
  ValidatedTableRequest,
} from "core/types";
import {
  CloseOutlinedIcon,
  DoneOutlinedIcon,
  NoEncryptionOutlinedIcon,
  StarBorderIcon,
  StarIcon,
} from "ds/icons";
import { TextInputField } from "ds_legacy/components/TextInputField";
import Tooltip from "ds_legacy/components/Tooltip";
import {
  ICON_GREY,
  SUCCESS_COLOR,
  UNAVAILABLE_GREY,
  WHITE,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding, translate } from "ds_legacy/materials/metrics";
import {
  FONT_SIZE_10,
  FONT_SIZE_14,
  FONT_SIZE_16,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { usePotentialReceiverContext } from "dsl/ecosystems/PatientSearchMerge/Components/SearchPageTables/Tables/PotentialReceiverTable/PotentialReceiverContext";
import { shouldDisableActions } from "dsl/ecosystems/PatientSearchMerge/Components/Shared/utils";
import { useRequestAction } from "dsl/hooks";
import React, { useEffect, useState } from "react";
import { useTranslations } from "translations";
import {
  COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT,
  COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS,
} from ".";
import { CellProps, DefaultTextCell } from "..";
import { DefaultCellBodyText } from "../../styles";

type PatientPreferredInput = {
  id: number;
  patient_preferred: boolean;
  patient_preferred_reason: string | undefined;
};

type PatientPreferredCellRequest =
  | AcceptedTableRequest
  | ContactedTableRequest
  | DeclinedTableRequest
  | PotentialReceiversTableCandidate
  | RejectedTableRequest
  | ValidatedTableRequest;

type StarTranslationType = {
  ariaLabel: string;
  tooltip: string;
};

const useGetStarTranslationType = ({
  careprovider,
  patientPreferred,
  shouldDisable,
}: {
  careprovider: PatientPreferredCellRequest["careprovider"];
  patientPreferred: boolean;
  shouldDisable: boolean;
}): StarTranslationType => {
  const translations = useTranslations();

  if (shouldDisable)
    return {
      tooltip: "",
      ariaLabel:
        translations.search.searchPageTables.accessibility
          .patientWishAccessibility.disabled,
    };

  if (patientPreferred) {
    return {
      tooltip:
        translations.search.patientPreferred.tooltipRemovePatientPreferred,
      ariaLabel:
        translations.search.searchPageTables.accessibility.patientWishAccessibility.checkedStar(
          { providerName: careprovider?.name ?? "" },
        ),
    };
  }
  return {
    tooltip: translations.search.patientPreferred.markAs,
    ariaLabel:
      translations.search.searchPageTables.accessibility.patientWishAccessibility.uncheckedStar(
        { providerName: careprovider?.name ?? "" },
      ),
  };
};

function PatientPreferredIconCheckbox<
  Request extends PatientPreferredCellRequest,
>({
  auctionRequest,
  patientPreferred,
  queryProgress,
  setLocalPatientPreferred,
  setPatientPreferred,
  shouldDisable,
}: {
  auctionRequest: Request;
  patientPreferred: boolean;
  queryProgress: QueryProgress | undefined;
  setLocalPatientPreferred: React.Dispatch<React.SetStateAction<string>>;
  setPatientPreferred: (value: PatientPreferredInput) => void;
  shouldDisable: boolean;
}) {
  const theme = useTheme();
  const starTranslationType = useGetStarTranslationType({
    careprovider: auctionRequest.careprovider,
    patientPreferred,
    shouldDisable,
  });

  const StarCheckboxIcon = patientPreferred ? StarIcon : StarBorderIcon;

  const handleIconClick = () => {
    if (queryProgress === QUERY_PROGRESS_PENDING) return;
    setPatientPreferred({
      id: auctionRequest.id,
      patient_preferred: !patientPreferred,
      patient_preferred_reason: undefined,
    });
    setLocalPatientPreferred("");
  };

  return (
    <Tooltip
      tooltipTextAlign="center"
      placement="bottom"
      title={starTranslationType.tooltip}
    >
      <IconButton
        data-testid={`patient-preferred-star-${auctionRequest?.careprovider?.id}`}
        size="small"
        disabled={shouldDisable}
        onClick={handleIconClick}
        aria-label={starTranslationType.ariaLabel}
        role="checkbox"
        aria-checked={patientPreferred}
        sx={{
          color: patientPreferred ? theme.palette.secondary.dark : "default",
          "&:disabled": { color: UNAVAILABLE_GREY },
        }}
      >
        <StarCheckboxIcon
          data-testid="preferred-star-icon"
          fill={patientPreferred ? theme.palette.secondary.dark : "none"}
          size={FONT_SIZE_14}
        />
      </IconButton>
    </Tooltip>
  );
}

const PreferredReasonInput = ({
  careproviderId,
  editingReason,
  localPatientPreferred,
  patientPreferredReason,
  preferredReasonUpdated,
  setEditingReason,
  setLocalPatientPreferred,
  shouldDisable,
}: {
  careproviderId: number;
  editingReason: boolean;
  localPatientPreferred: string;
  patientPreferredReason: string;
  preferredReasonUpdated: boolean;
  setEditingReason: React.Dispatch<React.SetStateAction<boolean>>;
  setLocalPatientPreferred: React.Dispatch<React.SetStateAction<string>>;
  shouldDisable: boolean;
}) => {
  const translations = useTranslations();
  // show input field only if there has been no reason entered
  //  or if they are currently editing their reason
  if (!patientPreferredReason || editingReason) {
    if (shouldDisable) return null;

    const handleClick = () => {
      if (editingReason) {
        return;
      }
      setEditingReason(true);
    };

    const handleBlur = () => {
      setLocalPatientPreferred(localPatientPreferred?.trim());
      // if the reason was edited dont blur field
      // and keep confirmation buttons visible
      if (preferredReasonUpdated) {
        return;
      }
      setEditingReason(false);
    };

    return (
      <TextInputField
        inputProps={{
          "aria-label":
            translations.search.searchPageTables.accessibility
              .patientWishAccessibility.addReason,
        }}
        testId={`preferred-text-input-${careproviderId}`}
        marginOverride={margin(0)}
        variant="outlined"
        width="auto"
        placeholder={translations.search.patientPreferred.reason}
        value={localPatientPreferred}
        elementName={`preferred-text-input-${careproviderId}`}
        onChange={setLocalPatientPreferred}
        startAdornment={
          <Tooltip
            title={translations.search.patientPreferred.dataNotEncrypted}
          >
            <NoEncryptionOutlinedIcon
              sx={{
                transform: translate({ y: 4, x: -3 }),
                margin: margin(0, -0.75),
                color: ICON_GREY,
              }}
              fontSize="small"
              size={FONT_SIZE_14}
            />
          </Tooltip>
        }
        onClick={handleClick}
        onBlur={handleBlur}
        // if the user clicks on the field to edit the reason auto focus the field
        autoFocus={!!patientPreferredReason}
      />
    );
  }

  return (
    <DefaultTextCell
      aria-label={
        translations.search.searchPageTables.accessibility
          .patientWishAccessibility.editReason
      }
      testId={`preferred-text-${careproviderId}`}
      width="100%"
      disabled={shouldDisable}
      onClick={() => {
        // open input field on click to allow for reason editing
        if (shouldDisable) return;
        setEditingReason(true);
      }}
      cursor={shouldDisable ? "auto" : "pointer"}
      message={localPatientPreferred}
    />
  );
};

function InputButtons<Request extends PatientPreferredCellRequest>({
  auctionRequest,
  editingReason,
  localPatientPreferred,
  patientPreferredReason,
  preferredReasonUpdated,
  setEditingReason,
  setLocalPatientPreferred,
  setPatientPreferred,
}: {
  auctionRequest: Request;
  editingReason: boolean;
  localPatientPreferred: string;
  patientPreferredReason: string;
  preferredReasonUpdated: boolean;
  setEditingReason: React.Dispatch<React.SetStateAction<boolean>>;
  setLocalPatientPreferred: React.Dispatch<React.SetStateAction<string>>;
  setPatientPreferred: (value: PatientPreferredInput) => void;
}) {
  const translations = useTranslations();

  const handleClose = () => {
    // if the reason was edited and user cancels editing, return to old reason
    if (preferredReasonUpdated)
      setLocalPatientPreferred(patientPreferredReason ?? "");
    setEditingReason(false);
  };

  const handleConfirm = () => {
    // only send updated reasons to BE
    setEditingReason(false);

    if (preferredReasonUpdated) {
      setPatientPreferred({
        id: auctionRequest.id,
        patient_preferred: true,
        patient_preferred_reason: localPatientPreferred?.length
          ? localPatientPreferred
          : undefined,
      });
    }
  };
  if (!editingReason) return null;

  return (
    <VerticalLayout justify="space-between" padding={padding(0.25)}>
      <CloseOutlinedIcon
        aria-label={
          translations.search.searchPageTables.accessibility
            .patientWishAccessibility.cancelReason
        }
        data-testid={`preferred-cancel-button-${auctionRequest?.careprovider?.id}`}
        style={{
          fontSize: FONT_SIZE_16,
          backgroundColor: ICON_GREY,
          borderRadius: "100%",
          marginRight: dp(4),
          cursor: "pointer",
          color: WHITE,
        }}
        size={FONT_SIZE_16}
        onClick={handleClose}
      />
      <Tooltip
        title={
          preferredReasonUpdated
            ? ""
            : translations.search.patientPreferred.reasonNotEmpty
        }
      >
        <DoneOutlinedIcon
          aria-label={
            translations.search.searchPageTables.accessibility
              .patientWishAccessibility.confirmReason
          }
          data-testid={`preferred-confirm-button-${auctionRequest?.careprovider?.id}`}
          style={{
            fontSize: FONT_SIZE_16,
            backgroundColor: preferredReasonUpdated ? SUCCESS_COLOR : ICON_GREY,
            borderRadius: "100%",
            cursor: preferredReasonUpdated ? "pointer" : "default",
            color: WHITE,
          }}
          size={FONT_SIZE_16}
          onClick={handleConfirm}
        />
      </Tooltip>
    </VerticalLayout>
  );
}

const PatientPreferredCellWrapper = ({
  careproviderId,
  children,
}: {
  careproviderId: number;
  children: React.ReactNode;
}) => {
  return (
    <HorizontalLayout
      width="100%"
      data-testid={`patient-preferred-cell-${careproviderId}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{
        columnGap: margin(0.25),
        alignItems: "center",
        cursor: "default",
      }}
    >
      {children}
    </HorizontalLayout>
  );
};

const PreferredReasonInputWrapper = ({
  children,
  patientPreferred,
}: {
  children: React.ReactNode;
  patientPreferred: boolean;
}) => {
  if (!patientPreferred) return null;
  return <HorizontalLayout style={{ flex: 1 }}>{children}</HorizontalLayout>;
};

export function shouldDisablePatientPreferred({
  auctionStatus,
  patientPreferred,
  table,
}: {
  auctionStatus: number | undefined;
  patientPreferred: boolean;
  table: SearchTable;
}): { shouldDisable: boolean; shouldHide: boolean } {
  const result = { shouldDisable: false, shouldHide: false };
  // all patient actions should be disabled if auction validated or stopped
  if (shouldDisableActions(auctionStatus)) {
    result.shouldDisable = true;
  }
  if (
    !(
      [SEARCH_TABLE_CONTACTED, SEARCH_TABLE_POTENTIAL] as SearchTable[]
    ).includes(table)
  ) {
    // if not in the contacted or potential receivers table patient preferred editing should always be disabled
    result.shouldDisable = true;
    // if not in these tables and not patient preferred dont show anything
    result.shouldHide = !patientPreferred;
  }

  return result;
}

export function PreferredIconInputPresenter<
  Request extends PatientPreferredCellRequest,
>({
  auction,
  auctionRequest,
  patientPreferred,
  patientPreferredReason = "",
  queryProgress,
  setPatientPreferred,
  table,
}: {
  auction: Auction;
  auctionRequest: Request;
  patientPreferred: boolean;
  patientPreferredReason: string | undefined;
  queryProgress?: QueryProgress;
  setPatientPreferred: (value: PatientPreferredInput) => void;
  table: SearchTable;
}) {
  const [localPatientPreferred, setLocalPatientPreferred] = useSafeState(
    patientPreferredReason,
  );

  useEffect(() => {
    setLocalPatientPreferred(patientPreferredReason);
  }, [patientPreferredReason]);

  const [editingReason, setEditingReason] = useState(false);

  const { shouldDisable, shouldHide } = shouldDisablePatientPreferred({
    auctionStatus: auction.status,
    table,
    patientPreferred,
  });

  if (shouldHide) {
    return null;
  }

  const preferredReasonUpdated =
    (patientPreferredReason?.trim() || "") !==
    (localPatientPreferred?.trim() || "");

  return (
    <PatientPreferredCellWrapper
      careproviderId={auctionRequest?.careprovider?.id || -1}
    >
      <PatientPreferredIconCheckbox
        queryProgress={queryProgress}
        shouldDisable={shouldDisable}
        patientPreferred={patientPreferred}
        setLocalPatientPreferred={setLocalPatientPreferred}
        auctionRequest={auctionRequest}
        setPatientPreferred={setPatientPreferred}
      />
      <PreferredReasonInputWrapper patientPreferred={patientPreferred}>
        <PreferredReasonInput
          careproviderId={auctionRequest?.careprovider?.id || -1}
          shouldDisable={shouldDisable}
          patientPreferredReason={patientPreferredReason}
          preferredReasonUpdated={preferredReasonUpdated}
          localPatientPreferred={localPatientPreferred}
          setLocalPatientPreferred={setLocalPatientPreferred}
          setEditingReason={setEditingReason}
          editingReason={editingReason}
        />
        <InputButtons
          auctionRequest={auctionRequest}
          preferredReasonUpdated={preferredReasonUpdated}
          patientPreferredReason={patientPreferredReason}
          localPatientPreferred={localPatientPreferred}
          setLocalPatientPreferred={setLocalPatientPreferred}
          setPatientPreferred={setPatientPreferred}
          setEditingReason={setEditingReason}
          editingReason={editingReason}
        />
      </PreferredReasonInputWrapper>
    </PatientPreferredCellWrapper>
  );
}

export function PreferredIconInput({
  auction,
  auctionRequest,
  invalidate,
  refresh,
  table,
}: CellProps<typeof COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT>) {
  const print = usePrint();
  const [setPatientPreferred, queryProgress] = useRequestAction({
    actionType: REQUEST_ACTION_PATIENT_PREFERRED,
    auctionRequest: auctionRequest as unknown as AuctionRequest,
  });

  if (print)
    return (
      <HorizontalLayout>
        {auctionRequest?.patient_preferred && (
          <StarIcon
            style={{
              margin: `auto ${dp(8)} auto ${dp(0)} `,
              fontSize: FONT_SIZE_10,
            }}
            size={FONT_SIZE_10}
          />
        )}
        <DefaultCellBodyText>
          {auctionRequest?.patient_preferred_reason ?? ""}
        </DefaultCellBodyText>
      </HorizontalLayout>
    );

  return (
    <PreferredIconInputPresenter
      table={table}
      setPatientPreferred={(props: PatientPreferredInput) =>
        setPatientPreferred({
          context: props,
          onError: () => refresh?.(),
          onCompleted: () => invalidate?.(),
        })
      }
      auction={auction}
      queryProgress={queryProgress}
      auctionRequest={auctionRequest}
      patientPreferred={!!auctionRequest?.patient_preferred}
      patientPreferredReason={auctionRequest?.patient_preferred_reason}
    />
  );
}

export function PreferredIconInputPotentalReceiver({
  auction,
  auctionRequest,
  table,
}: CellProps<
  typeof COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS
>) {
  const { getRequestState, setPatientPreferred } =
    usePotentialReceiverContext();
  const requestState = getRequestState(auctionRequest.id);

  return (
    <PreferredIconInputPresenter
      table={table}
      setPatientPreferred={setPatientPreferred}
      auction={auction}
      auctionRequest={auctionRequest}
      patientPreferred={!!requestState?.patient_preferred}
      patientPreferredReason={requestState?.patient_preferred_reason}
    />
  );
}
